interface CacheResult {
  data: any;
  expireTime?: number
}
export function getItem (key: string) {
  const rawresult = window.localStorage.getItem(key)
  if (!rawresult) { return null }
  const result = JSON.parse(rawresult)

  if (result) {
    if (result.expireTime <= Date.now()) {
      window.localStorage.removeItem(key)
      return null
    }
    return result.data
  }
  return null
}
export function setItem (key: string, value: any, maxAge = 30 * 30 * 60 * 1000) {
  const result: CacheResult = { data: value }
  if (maxAge) {
    result.expireTime = Date.now() + maxAge
  }
  window.localStorage.setItem(key, JSON.stringify(result))
}
export function removeItem (key: string) {
  window.localStorage.removeItem(key)
}
